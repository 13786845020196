<template>
    <div>
        <page-header :title="`Tạo mới người dùng từ tệp Excel`" :items="breadcrumbs" />
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <b-col sm="5">
                        <b-link class="style-link" :href="downloadFile(media)"><i class="bx bx-download mr-2"></i>
                            Tải xuống tệp mẫu
                        </b-link>
                    </b-col>
                </div>
                <div class="row">
                    <b-col lg="4" class="mt-4">
                        <label>Tải lên tệp excel</label>
                        <a-file class="my-pond" @input="updateFile"/>
                    </b-col>
                </div>
                <b-form class="row" v-show="message != ''">
                    <div class="col-lg-4">
                       <b-alert class="alert alert-danger" show>{{message}}</b-alert>
                    </div>
                </b-form>
                <b-col v-if="this.file != ''" sm="5" class="mt-1 pl-0">
                    <b-button class="style-center" variant="success" @click.prevent="create">
                        <i class="bx bx-plus mr-2"></i>
                        Tạo mới tài khoản
                    </b-button>
                </b-col>
            </div>
        </div>
        <div v-if="entries.length > 0" class="card">
            <div class="card-body">
                <!-- Table data -->
                <div class="table-responsive">
                    <table class="table table-hover table-centered table-nowrap">
                        <thead class="thead-light">
                            <tr>
                                <th>STT</th>
                                <th scope="col">Tên đăng nhập</th>
                                <th scope="col">Họ và tên</th>
                                <th scope="col">Tỉnh thành</th>
                                <th scope="col">Quận/huyện</th>
                                <th scope="col">Phường/xã</th>
                                <th scope="col">Địa chỉ</th>
                                <th scope="col">Cấp quyền</th>
                                <th scope="col">Chức vụ</th>
                                <th scope="col" class="text-center style-width">Kết quả</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(entry, index) in entries" :key="entry.id">
                                <td>
                                    {{ index + 1 }}
                                </td>
                                <td>
                                    {{ entry.id }}
                                </td>
                                <td>
                                    {{ entry.name | capitalising }}
                                </td>
                                <td>
                                    {{ entry.province }}
                                </td>
                                <td>
                                    {{ entry.district }}
                                </td>
                                <td>
                                    {{ entry.ward }}
                                </td>
                                <td>
                                    {{ entry.address }}
                                </td>
                                <td>
                                    {{ entry.role_level | filterRole }}
                                </td>
                                <td>
                                    {{ entry.position }}
                                </td>
                                <td class="text-center">
                                    <b-button v-if="entry.message == 'Thành công'" variant="outline-success" disabled>
                                        {{ entry.message }}</b-button>
                                    <b-button v-else style="white-space: pre-line;" variant="outline-danger" disabled>
                                        {{ entry.message }}</b-button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PageHeader from '../../../components/page-header';
import Swal from 'sweetalert2';
import {
    httpClient
} from "../../../_utils/httpClient";
import {
    httpPreview
} from "../../../_utils/httpPreview";
import AFile from './partials/a-file.vue'

export default {
    name: 'list',
    components: {
        PageHeader,
        AFile,
    },
    data: () => ({
        search: '',
        breadcrumbs: [{
            text: 'Trang chủ',
            to: '/admin/home',
        }, {
            text: 'Người dùng',
            to: '/admin/users',
        },
        {
        text: 'Thêm mới',
        to: '#',
        active: true,
      }],
        entries: [],
        file: "",
        media: "",
        message: ''
    }),
    filters: {
        filterRole(role_level) {
            switch (role_level) {
                case 1:
                    return 'Cán bộ tỉnh'
                case 2:
                    return 'Cán bộ huyện'
                case 3:
                    return 'Cán bộ xã'
                case 4:
                    return 'Cán bộ xử lý'
                case 99:
                    return 'Người dân'
                default:
                    return 'Người dân'
            }
        }
    },
    watch: {
        file() {
            this.message = ""
        }
    },
    methods: {
        updateFile(file) {
            this.file = file;
        },
        async create() {
            await Swal.queue([{
                title: 'Tạo mới danh sách người dùng !',
                confirmButtonText: 'Lưu',
                cancelButtonText: 'Hủy bỏ',
                cancelButtonColor: '#f46a6a',
                confirmButtonColor: '#34c38f',
                showCancelButton: true,
                showconfirmButton: true,
                preConfirm: async () => {
                    const formData = new FormData();
                    formData.append('file', this.file.file);
                    console.log(formData,"form");
                    let response = await httpClient.post('/user/import', formData)
                    if(response.toString().includes('status code 500')) {
                        this.message = 'Tệp không hợp lệ! Vui lòng chọn tệp khác!'
                        return true
                    }
                    if (response.data.code == 1) {
                        this.message = 'Tệp không hợp lệ! Vui lòng chọn tệp khác!'
                        return true
                    }
                    if (response.data.code === 0) {
                        this.message = ''
                        Swal.insertQueueStep({
                            title: 'Tạo mới danh sách người dùng thành công'
                        })
                        this.remove_file()
                        this.entries = response.data.data
                    }
                    return true;
                }

            },]);
        },
        downloadFile(media) {
            if (media && media.startsWith(`http`)) return media
            return httpPreview + media
        },
        async getData() {
            let response = await httpClient.get(`config`, {
                params: {
                    name: 'templateuserfile'
                }
            })
            this.media = response.data.data[0].value
        },
        remove_file() {
            document.querySelector('.filepond--file-action-button.filepond--action-revert-item-processing').click()
            this.file = ""
        }
    },
    async created() {
        await this.getData();
        document.querySelector('.my-pond').addEventListener('FilePond:removefile', () => {
            this.file = ""
        });
    },
};
</script>

<style scoped>
.style-width {
    min-width: 200px;
}

.style-link {
    color: #50a5f1;
    border: 1px solid #50a5f1;
    padding: 10px;
    border-radius: 4px;
    min-width: 143px !important;
}

.style-link:hover {
    background-color: #50a5f1;
    color: #fff;
}

.style-form {
    background-color: #ccc;
    padding: 25px 10px;
    border-radius: 4px;
    position: relative;
}

.style-form input {
    font-size: 0;
    transform: translateX(-10000px);
}

.style-text {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 4px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(44, 44, 44);
    cursor: pointer;
    word-break: break-word;
    display: -webkit-box;
    overflow: hidden;
}

.style-file {
    color: rgb(22, 160, 6);
    background-color: #ccc;
}

.red {
    color: red;
}

.blue {
    color: blue;
}

.style-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-x {
    position: absolute;
    right: 4px;
    top: 4px;
    z-index: 1;
    font-size: 18px;
    color: rgb(110, 110, 110);
    font-weight: 500;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.4s;
    width: 24px;
    height: 24px;
    text-align: center;
}

.icon-x:hover {
    color: rgb(10, 10, 10);
}
</style>
